import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";

const util = {
  data() {
    return {
      categoryColors: {
        1: "table-primary",
        2: "table-success",
        3: "table-info",
        4: "table-danger",
        5: "table-warning",
      },
      complaintStatus: {
        PENDING: 1,
        PROCESSING: 2,
        FINALIZED: 3,
        CLOSED: 4,
      },
      nocStatus: {
        PENDING: 1,
        PROCESSING: 2,
        FINALIZED: 3,
      },
      userTypesArr: [
        {
          id: 1,
          name: "Serving"
        },
        {
          id: 2,
          name: "Retired"
        },
        {
          id: 3,
          name: "Civilan"
        }
      ],
      userTypes: {
        SERVING: 1,
        RETIRED: 2,
        CIVILIAN: 3,
      },
      categoryColorsHex: [
        "#094120",
        "#28a745",
        "#17a2b8",
        "#dc3545",
        "#ffc107",
        "#094120",
        "#28a745",
        "#17a2b8",
        "#dc3545",
        "#ffc107",
        "#094120",
        "#28a745",
        "#17a2b8",
        "#dc3545",
        "#ffc107",
        "#094120",
        "#28a745",
        "#17a2b8",
        "#dc3545",
        "#ffc107",
        "#094120",
        "#28a745",
        "#17a2b8",
        "#dc3545",
        "#ffc107",
        "#094120",
        "#28a745",
        "#17a2b8",
        "#dc3545",
        "#ffc107",
      ],
      showModalPlace: {
        TYPE_TABLE: 1,
        STATISTICS: 2,
        CAT_TABLE: 3,
        AG_APPROVED_REJECTED: 4,
        EXCEEDED: 5,
        RECEIVED: 6,
        USER: 7,
      },
      finalApproval: {
        DEFAULT: 0,
        APPROVED: 1,
        REJECTED: 2,
      },
      searchTypes: [
        { value: 1, name: "Case No" },
        { value: 2, name: "PA No" },
        { value: 3, name: "CNIC" },
        { value: 4, name: "Title" },
        { value: 5, name: "Status" },
        { value: 6, name: "Mobile Number" },
        { value: 7, name: "Date Range" },
        { value: 8, name: "Sponsor Organization" },
      ],
    };
  },
  methods: {
    userTypeNames(userType) {
      if (userType == this.userTypes.SERVING) {
        return "Serving";
      }
      if (userType == this.userTypes.RETIRED) {
        return "Retired";
      }
      if (userType == this.userTypes.CIVILIAN) {
        return "Civilian";
      }
    },
    getNocStatusByValue(value) {
      switch (value) {
        case 1:
          return "Pending";
        case 2:
          return "Processing";
        case 3:
          return "Finalized";
        case 4:
          return "Expired";
      }
    },

    displayError(error) {
      try {
        let msgs = [];
        if (!error.response || error.response.status === 401) {
          // msgs.push("Error: Network Error");
        } else if (!error.response || error.response.status === 500) {
          msgs.push("Error: Network Error");
        } else if (error.response.status === 500) {
          msgs.push("Error: Server Error");
        } else if (typeof error.response.data === "string") {
          msgs.push(error.response.data);
        } else if (error.response.data.msg) {
          msgs.push(error.response.data.msg);
        } else {
          for (const key in error.response.data) {
            const element = error.response.data[key];
            if (Array.isArray(element)) {
              msgs.push(element[0]);
            } else {
              msgs.push(element);
            }
          }
        }
        for (let i = 0; i < msgs.length; i++) {
          const msg = msgs[i];
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                text: msg,
                variant: "danger",
              },
            },
            {
              timeout: 2000,
              position: "top-center",
            }
          );
        }
      } catch (e) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              text: "Network Error !",
              variant: "danger",
            },
          },
          {
            timeout: 2000,
            position: "top-center",
          }
        );
      }
    },
    displaySuccess(success) {
      const msg = success;
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "CheckIcon",
            text: msg,
            variant: "success",
          },
        },
        {
          timeout: 4000,
          position: "top-right",
        }
      );
    },

    displayCustomError(error) {
      const msg = error;
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            text: msg,
            variant: "danger",
          },
        },
        {
          timeout: 2000,
          position: "top-center",
        }
      );
    },
    getUserTypeValue(value) {
      switch (value) {
        case 1:
          return "SERVING";
        case 2:
          return "RETIRED";
        case 3:
          return "CIVILIAN";
      }
    },
    getPetitionMarkedToValue(markedTo) {
      switch (markedTo) {
        case 1:
          return "COAS";
        case 2:
          return "AG Branch";
        case 3:
          return "PA";
        case 4:
          return "PS";
        case 5:
          return "W&R";
        case 6:
          return "Med";
        case 7:
          return "Housing";
        case 8:
          return "PP&A";
        case 9:
          return "Law";
        case 10:
          return "CP";
        case 11:
          return "Regs";
        case 12:
          return "JAG";
      }
    },
    getPetitionLocationValue(location) {
      switch (location) {
        case 1:
          return "Inside";
        case 2:
          return "Outside";
        case 3:
          return "Call Center";
      }
    },
    getPetitionStatusValue(status) {
      switch (status) {
        case 1:
          return "Pending";
        case 2:
          return "Processing";
        case 3:
          return "Closed";
      }
    },

    // complaint methods

    getDateString(date) {
      return moment(date).format("Do MMMM YYYY on HH:mm");
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      return this.categoryColors[item.category];
    },
    rowClassCategoryHex(category) {
      return this.categoryColorsHex[category - 1];
    },
    statusNamesColor(status) {
      let name = "";
      switch (status) {
        case 1:
          name = "info";
          break;
        case 2:
          name = "warning";
          break;
        case 3:
          name = "success";
          break;
        case 4:
          name = "primary";
          break;
      }
      return name;
    },
    statusNames(status) {
      let name = "";
      switch (status) {
        case 1:
          name = "Pending";
          break;
        case 2:
          name = "Processing";
          break;
        case 3:
          name = "Finalized";
          break;
        case 4:
          name = "Closed";
          break;
        case 5:
          name = "Submitted";
          break;
      }
      return name;
    },
    typeName(type) {
      let name = "";
      switch (type) {
        case 1:
          name = "Army";
          break;
        case 2:
          name = "Civilian";
          break;
        case 3:
          name = "Anonymous";
          break;
        default:
          break;
      }
      return name;
    },
    openFile(path) {
      window.open(path, "_blank");
    },
    // displayError(error) {
    //   const msgs = [];
    //   if (
    //     !error.response ||
    //     error.response.status === 500 ||
    //     typeof error.response.data === "string"
    //   ) {
    //     msgs.push("Error: Server Error");
    //   } else {
    //     const data = error.response.data;
    //     for (let key in data) {
    //       const element = data[key];
    //       if (Array.isArray(element)) {
    //         if (typeof element[0] === "string") {
    //           if (key !== "msg") {
    //             msgs.push(`${key}: ${element[0]}`);
    //           } else {
    //             msgs.push(`${element[0]}`);
    //           }
    //         } else if (typeof element[0] === "object") {
    //           for (const key in element[0]) {
    //             if (key !== "msg") {
    //               msgs.push(`${key}: ${element[0][key][0]}`);
    //             } else {
    //               msgs.push(`${element[0][key][0]}`);
    //             }
    //           }
    //         }
    //       } else if (typeof element === "object") {
    //         for (const key in element) {
    //           if (key !== "msg") {
    //             msgs.push(`${key}: ${element[key][0]}`);
    //           } else {
    //             msgs.push(`${element[key][0]}`);
    //           }
    //         }
    //       } else {
    //         if (element !== "Invalid page.") {
    //           msgs.push(element);
    //         }
    //       }
    //     }
    //   }

    //   for (let i = 0; i < msgs.length; i++) {
    //     const msg = msgs[i];
    //     this.$toast(
    //       {
    //         component: ToastificationContent,
    //         props: {
    //           title: "Error",
    //           icon: "BellIcon",
    //           text: msg,
    //           variant: "danger",
    //         },
    //       },
    //       {
    //         timeout: 2000,
    //         position: "top-center",
    //       }
    //     );
    //   }
    // },
  },
};

export default util;
